import { zodResolver } from '@hookform/resolvers/zod'
import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
} from '@remix-run/cloudflare'
import { json } from '@remix-run/cloudflare'
import {
  Form,
  useLoaderData,
  useNavigation,
  useSearchParams,
} from '@remix-run/react'
import { useEffect, useRef } from 'react'
import { z } from 'zod'
import {
  RemixFormProvider,
  getValidatedFormData,
  useRemixForm,
} from '~/lib/remix-hook-form'

import { Logo } from '~/components/layout/layout'
import { Button } from '~/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form'
import { Input } from '~/components/ui/input'

const schema = z.object({
  username: z
    .string()
    .min(3, 'Kullanıcı Adı en az 3 karakter olmalı')
    .max(30, 'Kullanıcı Adı en fazla 30 karakter olmalı'),
  password: z
    .string()
    .min(3, 'Parola en az 3 karakter olmalı')
    .max(30, 'Parola en fazla 30 karakter olmalı'),
})

type FormData = z.infer<typeof schema>

const resolver = zodResolver(schema)

export const action = async ({ request, context }: ActionFunctionArgs) => {
  const { errors, data, receivedValues } = await getValidatedFormData<FormData>(
    request,
    resolver,
  )
  if (errors) {
    return json({ success: false, errors, defaultValues: receivedValues })
    // return redirect('/')
  }

  const formData = new FormData()
  for (const key in receivedValues) {
    formData.append(key, receivedValues[key])
  }
  const req = new Request(request.url, {
    method: 'POST',
    body: formData,
  })

  await context.authenticator.authenticate('form', req, {
    successRedirect: '/',
    failureRedirect: '/login',
  })
}

type LoaderError = { message: string } | null

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  await context.authenticator.isAuthenticated(request, { successRedirect: '/' })
  const session = await context.sessionStorage.getSession(
    request.headers.get('Cookie'),
  )
  const error = session.get(
    context.authenticator.sessionErrorKey,
  ) as LoaderError
  return json({ error })
}

export default function LoginRoute() {
  const loaderData = useLoaderData<typeof loader>()
  const form = useRemixForm({
    mode: 'onSubmit',
    defaultValues: {
      username: '',
      password: '',
    },
    resolver,
  })

  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo') || '/'
  const navigation = useNavigation()
  const isLoading =
    navigation.state === 'loading' && navigation.formAction === '/auth/google'

  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (formRef.current) {
      // formRef.current.submit()
    }
  }, [])

  return (
    <div className="flex h-screen flex-col bg-gray-200">
      <div className="flex flex-1 flex-col items-center px-4 py-12">
        <div className="mb-20 w-[300px] rounded-lg border border-gray-300 bg-white p-8">
          <div className="flex justify-center">
            <Logo className="mb-8 w-56" />
          </div>
          <RemixFormProvider {...(form as any)}>
            <Form method="post" onSubmit={form.handleSubmit}>
              <FormField
                control={form.control}
                name="username"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Kullanıcı Adı:</FormLabel>
                    <FormControl>
                      <Input {...field} autoComplete="username" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Parola:</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        type="password"
                        autoComplete="current-password"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {loaderData.error ? (
                <div className="mt-1 text-sm text-red-600">
                  {loaderData.error.message}
                </div>
              ) : null}

              <Button className="mt-4">Giriş</Button>
            </Form>
          </RemixFormProvider>
        </div>
      </div>
    </div>
  )
  // return (
  //   <>
  //     <SocialButton
  //       provider={AuthStrategies.GOOGLE}
  //       label="Login with google"
  //     />
  //   </>
  // )
}

// interface SocialButtonProps {
//   provider: AuthStrategy
//   label: string
// }
// const SocialButton = ({ provider, label }: SocialButtonProps) => (
//   <Form action={`/auth/${provider}`} method="post">
//     <button>{label}</button>
//   </Form>
// )
